/* global PUBLIC_PATH */
import axios from 'axios';
import { render, html } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import { until } from 'lit-html/directives/until';
import Uppy from '@uppy/core';
import FileInput from '@uppy/file-input';
import XHRUpload from '@uppy/xhr-upload';
import ProgressBar from '@uppy/progress-bar';

function getMaxVisibleFiles() {
    if (window.matchMedia('(max-width: 370px)').matches) {
        return 1;
    }

    if (window.matchMedia('(max-width: 500px)').matches) {
        return 2;
    }

    if (window.matchMedia('(max-width: 630px)').matches) {
        return 3;
    }

    if (window.matchMedia('(max-width: 735px)').matches) {
        return 4;
    }

    if (window.matchMedia('(max-width: 767px)').matches) {
        return 5;
    }

    if (window.matchMedia('(max-width: 900px)').matches) {
        return 3;
    }

    if (window.matchMedia('(max-width: 1024px)').matches) {
        return 4;
    }

    if (window.matchMedia('(max-width: 1400px)').matches) {
        return 2;
    }

    return 3;
}

export default (el) => {
    const tempFilesEndpoint = `/local/ajax/file_upload.php`;

    const label = el.querySelector('.file-upload__label');
    const progressbar = el.querySelector('.js-file-upload-progress-bar');

    let timer;
    const form = el.closest('.js-form-with-upload');
    const submitBtn = form.querySelector('button[type="submit"], input[type="submit"]');
    const uiContainer = el.querySelector('.js-file-upload-ui');
    let files = [];
    let isPopupVisible = false;

    async function removeFileFromTempStorage(fileId) {
        try {
            const response = await axios.post(tempFilesEndpoint, {
                action: 'remove',
                sessId: window.sessId,
                formId: form.id,
                loadTimestamp: window.loadTimestamp,
                fileId,
            });

            if (response.data.success) {
                filesIds = filesIds.filter((id) => id === fileId);
            } else {
                throw new Error(response.data.message);
            }
        } catch (err) {
            //
        } finally {
            //
        }
    }

    function showPopup() {
        isPopupVisible = true;
        const form = el.closest('.js-form-with-upload');
        const modalEl = el.closest('.app-modal-container');
        clearTimeout(timer);

        if (form) {
            form.classList.add('form-with-upload--list-opened');
        }

        updateUI();
    }

    function hidePopup() {
        isPopupVisible = false;

        if (form) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                form.classList.remove('form-with-upload--list-opened');
            }, 300);
        }

        updateUI();
    }

    const liTemplate = (file, className) => html`
        <li class="${className}">
            <div class="file-upload-item">
                <button
                    type="button"
                    class="file-upload-item__remove-btn"
                    aria-label="Удалить"
                    @click="${() => removeFile(file)}"
                >
                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 1.5L10.1895 10.1895" stroke="white" stroke-width="2" />
                        <path d="M10.1895 1.5L1.5 10.1895" stroke="white" stroke-width="2" />
                    </svg>
                </button>
                ${/^image\/*/.test(file.type) && file._previewSrc
                    ? html`
                          <div class="file-upload-item__preview">
                              <img src="${file._previewSrc}" alt="" decoding="async" />
                          </div>
                      `
                    : ''}
                <span class="file-upload-item__name">${file.name}</span>
            </div>
        </li>
    `;

    const uiTemplate = () => html`
        ${files.length > 0
            ? html`<div class="file-upload-list-row">
                <div class="file-upload-list-container">
                    <ul class="list-unstyled file-upload-list">
                        ${files
                            .slice(0, getMaxVisibleFiles())
                            .map((fileObj) => liTemplate(fileObj.file, 'file-upload-list__item'))}
                    </ul>
                </div>
                <div class="file-upload-show-more">
                    ${
                        files.length > getMaxVisibleFiles()
                            ? html`
                                  <button type="button" class="file-upload-item__show-more-btn" @click="${showPopup}">
                                      <span class="file-upload-item__show-more-btn__text">Показать все файлы</span>
                                      <svg
                                          width="20"
                                          height="19"
                                          viewBox="0 0 20 19"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                      >
                                          <path d="M10 1.51477V17.8998" stroke="#3C3C3C" stroke-width="2" />
                                          <path d="M18.1925 9.70727H1.80751" stroke="#3C3C3C" stroke-width="2" />
                                      </svg>
                                  </button>
                              `
                            : ''
                    }
                </div>
            </div>
        </div>`
            : ''}
        <div
            class="${classMap({
                'file-upload-item__all': true,
                'is-visible': isPopupVisible,
            })}"
        >
            <button type="button" class="file-upload-item__all-close-btn" @click="${hidePopup}">
                Вернуться к форме
            </button>
            ${files.length > 0
                ? html`<ul class="list-unstyled file-upload-item__all-list">
                      ${files.map((fileObj) => liTemplate(fileObj.file, 'file-upload-item__all-list__item'))}
                  </ul>`
                : ''}
        </div>
    `;

    function removeFile(file) {
        const fileToRemove = files.find((fileObj) => fileObj.file === file);

        if (fileToRemove) {
            uppy.removeFile(fileToRemove.id);
            removeFileFromTempStorage(fileToRemove.fileId);
            files = files.filter((fileObj) => fileObj.file !== file);
        }

        updateUI();
    }

    function createFile(file, id, previewSrc, fileId) {
        file._previewSrc = previewSrc;
        files.push({ file, id, fileId });
        updateUI();
    }

    function updateUI() {
        if (uiContainer) {
            render(uiTemplate(), uiContainer);
        }
    }

    function clear() {
        files = [];
        updateUI();
    }

    window.addEventListener('resize', updateUI);

    form?.addEventListener('send', (event) => {
        const ajaxFormSender = event.detail;

        files.forEach((fileObj) => {
            ajaxFormSender.data.append('file_id[]', fileObj.fileId);
        });
    });

    const uppy = new Uppy({
        pretty: false,
        autoProceed: true,
        maxNumberOfFiles: 40,
        allowedFileTypes: [
            'image/*',
            '.doc',
            '.docx',
            '.rtf',
            '.xls',
            '.xlsx',
            '.pdf',
            '.xml,application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ],
        meta: {
            action: 'add',
            sessId: window.jsSessId,
            formId: form.id,
            loadTimestamp: window.loadTimestamp,
        },
    });

    uppy.use(FileInput, {
        target: label,
    });

    uppy.use(ProgressBar, {
        target: progressbar,
        hideAfterFinish: true,
    });

    uppy.use(XHRUpload, {
        endpoint: tempFilesEndpoint,
        formData: true,
        fieldName: 'file',
    });

    uppy.on('upload', () => {
        if (submitBtn) {
            submitBtn.disabled = true;
        }
    });

    uppy.on('upload-success', (file, response) => {
        createFile(file.data, file.id, response.body.data.previewSrc, response.body.data.fileId);
    });

    uppy.on('complete', () => {
        if (submitBtn) {
            submitBtn.disabled = false;
        }
    });

    form.addEventListener('success', () => {
        clear();
        uppy.cancelAll();
    });
};
