/* eslint-disable max-lines */
import '../css/app.scss';
import './polyfills';
import Glide from '@glidejs/glide';
import { TweenLite, TimelineLite, Power2 } from 'gsap/all';
import 'dragscroll';
import './gsap';
import Modal from '../modules/modal';
import floatedLabels from './components/floated-labels';
import ScreenNav from './components/screen-nav';
import withCounter from './components/screen-nav/with-counter';
import withNav from './components/screen-nav/with-nav';
import withName from './components/screen-nav/with-name';
import ServicesSwitcher from './components/services-switcher';
import { listenOnce, withLeadingZero, findParent } from './utils';
import loadScript from '../modules/load-script';
import Cursor from './components/cursor';
import Map from './components/map';
import createFileUpload from './components/file-upload';
import initPreloader from './components/preloader';
import playStartingAnimation from './starting-animation';
import createArrows from './utils/create-arrows';
import serviceSlider from './inits/service-slider';
import headerObserve from './inits/header-theme-observer';
import revealInit from './inits/reveal';
import cityPicker from './inits/city-picker';
import mapPopup from './inits/map-popup';
import mapCity from './inits/map-city';
import mapSearch from './inits/map-search';
import { lazyload } from './inits/lazyload';
import loadMore from './inits/load-more';
import gallery from './inits/gallery';
import gallerySlider from './inits/gallery-slider';
import dragSliders from './inits/drag-sliders';
import initContentList from './inits/content-list';
import wysiwygRevealInit from './inits/wysiwyg-reveal';
import initAboutIndexSlider from './inits/about-index-slider';
import { YandexMap } from './components/YandexMap/YandexMap';
import initSelects from './inits/selects';
import actionsButtonInit from './inits/actions-button';
import cityPickerSearch from './inits/city-picker-search';

import './init-masks';
import './init-sticky-header';
import './init-tabs';

import './components/Collapse/Collapse';

// import './components/cookies-banner';

import vhMobileFix from '../modules/vh-mobile-fix';
import videoSlider from './inits/video-slider';
import InitPreviewSwitch from './inits/preview-switch';

vhMobileFix();

const loadAjaxFormSender = () =>
    import(/* webpackChunkName: "ajax-form-sender" */ '../modules/ajax-form-sender').catch((err) => {
        throw err.message || err;
    });

const loadValidator = () =>
    import(/* webpackChunkName: "validator" */ '../modules/validator')
        .then((module) => module.default)
        .catch((err) => {
            throw err.message || err;
        });

const loadAndApplyOFIPolyfill = () =>
    import(/* webpackChunkName: "object-fit-images" */ 'object-fit-images')
        .then((module) => module.default)
        .then((objectFitImages) => objectFitImages())
        .catch((err) => {
            throw new Error(err);
        });

document.addEventListener('DOMContentLoaded', () => {
    document.documentElement.classList.add('js-ready');
    revealInit();
    wysiwygRevealInit();
    cityPicker.init();
    lazyload.update();

    const preview = document.querySelector('video.js-preview');
    const isUsedHash = (hash) => ['main', 'services', 'about', 'vacancies', 'partnership', 'contacts'].includes(hash);
    // let peopleServicesSwitcher;
    // let businessServicesSwitcher;

    // Generate screen counter for mobile

    function createStaticCounter(container, { currentValue = 1, totalValue, className = '' }) {
        const counter = document.createElement('div');
        counter.className = `screen-counter screen-counter--animated ${className}`;

        const current = document.createElement('div');
        current.className = 'screen-counter__current';
        current.textContent = withLeadingZero(currentValue);
        counter.appendChild(current);

        const total = document.createElement('div');
        total.className = 'screen-counter__total';
        total.textContent = withLeadingZero(totalValue);
        counter.appendChild(total);

        container.appendChild(counter);

        return counter;
    }

    const screens = Array.from(document.querySelectorAll('.js-screen'));
    screens.forEach((screen, screenIndex) => {
        const mobileCounters = Array.from(screen.querySelectorAll('.js-screen-counter-mobile'));
        mobileCounters.forEach((container) => {
            const theme = container.dataset.theme || 'light';

            createStaticCounter(container, {
                currentValue: screenIndex + 1,
                totalValue: screens.length,
                className: `screen-counter--${theme}`,
            });
        });
    });

    // Object Fit for IE
    if (window.CSS) {
        if (!window.CSS.supports('object-fit', 'cover')) {
            loadAndApplyOFIPolyfill();
        }
    } else {
        loadAndApplyOFIPolyfill();
    }

    // Cursor

    const ua = window.navigator.userAgent.toLowerCase();
    let cursor;

    if (!(/trident/gi.test(ua) || /msie/gi.test(ua))) {
        // if not IE
        cursor = new Cursor();
        cursor.init();
    }

    // Menu on mobile

    let menu;

    const initMenu = () => {
        if (menu instanceof Modal) {
            menu.init();
        }
    };

    const destroyMenu = () => {
        if (menu instanceof Modal) {
            menu.destroy();
        }
    };

    if (window.matchMedia('(max-width: 1200px)').matches) {
        if (!menu) {
            menu = new Modal('menu');
            window.menu = menu;
        }

        initMenu();
    } else {
        destroyMenu();
    }

    // Screen Nav

    const slideDownBtn = document.querySelector('.js-main__next-slide-btn');
    const screenNavLinks = Array.from(document.querySelectorAll('.js-nav__link'));
    const servicesScreen = document.querySelector('[data-screen="services"]');

    const scrollToNextScreen = () => {
        TweenLite.to(window, 1, { scrollTo: servicesScreen.offsetTop });
    };

    function handleMobileLinkClick(event) {
        event.preventDefault();
        const screenId = this.href.substr(this.href.indexOf('#'));
        const scrollToElement = document.querySelectorAll(screenId);
        // если элементов на странцие несколкько (из-за таба - юр.лица/физ.лица)
        if (scrollToElement.length > 1) {
            scrollToElement.forEach((el) => {
                const tabParent = el.closest('[data-tab]');
                if (tabParent) {
                    if (tabParent.classList.contains('tab--active')) {
                        TweenLite.to(window, 1, { scrollTo: el });
                    }
                } else {
                    TweenLite.to(window, 1, { scrollTo: el });
                }
            });
        } else if (scrollToElement.length === 1) {
            if (this.classList.contains('to-main-page') && window.location.pathname !== '/') {
                // услсуги должны вести на главную страницу
                window.location.href = this.href;
            } else {
                TweenLite.to(window, 1, { scrollTo: screenId });
            }
        } else {
            window.location.href = this.href;
        }

        if (menu instanceof Modal) {
            menu.close();
        }
    }

    screenNavLinks.forEach((link) => link.addEventListener('click', handleMobileLinkClick));

    if (window.matchMedia('(max-width: 767px)').matches) {
        if (slideDownBtn) {
            slideDownBtn.addEventListener('click', scrollToNextScreen);
        }
    }

    // Preloaders

    const darkPreloader = document.querySelector('.js-preloader-dark');

    if (darkPreloader) {
        const transitionLinks = Array.from(
            document.querySelectorAll('a[data-transition="dark"]:not(.js-service-link)'),
        );

        transitionLinks.forEach((link) => {
            link.addEventListener('click', (event) => {
                if (
                    (document.body.classList.contains('main-page') && link.classList.contains('js-nav__link')) ||
                    event.which !== 1 ||
                    event.shiftKey ||
                    event.metaKey ||
                    event.altKey ||
                    event.ctrlKey
                ) {
                    return;
                }

                event.preventDefault();
                darkPreloader.classList.add('preloader--next-page');
                darkPreloader.classList.remove('preloader--leave');
                listenOnce(darkPreloader, 'transitionend', () => {
                    window.location.href = link.href;
                });
            });
        });
    }

    // File Uploaders

    Array.from(document.querySelectorAll('.js-file-upload')).forEach((el) => createFileUpload(el));

    // Custom scrollbar

    function applyCustomScrollbar() {
        if (window.chrome) {
            // Можно стилизовать скроллбар только на CSS, не надо тащить ненужные скрипты
            const dragscrollElements = Array.from(document.querySelectorAll('.lines-list'));
            dragscrollElements.forEach((el) => el.classList.add('dragscroll'));
        } else {
            import(/* webpackChunkName: "simplebar" */ 'simplebar')
                .then((module) => {
                    const SimpleBar = module.default;
                    const simplebarElements = Array.from(document.querySelectorAll('[data-simplebar]'));

                    simplebarElements.forEach((el) => {
                        // eslint-disable-next-line no-unused-vars
                        const simplebar = new SimpleBar(el);
                    });

                    const dragscrollElements = Array.from(
                        document.querySelectorAll('.vacancies-list-container--dragscroll .simplebar-content-wrapper'),
                    );
                    dragscrollElements.forEach((el) => el.classList.add('dragscroll'));
                })
                .catch((err) => {
                    throw new Error(err);
                });
        }
    }

    applyCustomScrollbar();

    // Floated Labels

    floatedLabels();

    // Services Switcher

    // const listSwitch = document.querySelector('.js-list-switch');

    // if (listSwitch) {
    //     peopleServicesSwitcher = new ServicesSwitcher('people');
    //     peopleServicesSwitcher.cursor = cursor;
    //     peopleServicesSwitcher.screenNav = screenNav;
    //     businessServicesSwitcher = new ServicesSwitcher('business');
    //     businessServicesSwitcher.cursor = cursor;
    //     businessServicesSwitcher.screenNav = screenNav;
    //     const currentSwitcherName = sessionStorage.getItem('current-services-switcher');

    //     if (currentSwitcherName) {
    //         if (currentSwitcherName === 'business') {
    //             listSwitch.checked = true;
    //         }
    //         businessServicesSwitcher.setActiveState(currentSwitcherName === 'business');
    //         peopleServicesSwitcher.setActiveState(currentSwitcherName !== 'business');
    //     }

    //     if (listSwitch instanceof HTMLInputElement) {
    //         listSwitch.addEventListener('change', () => {
    //             const tl = new TimelineLite({
    //                 onComplete: () => {
    //                     tl.kill();
    //                 },
    //             });

    //             const activeSwitcher = listSwitch.checked ? businessServicesSwitcher : peopleServicesSwitcher;
    //             const nonActiveSwitcher = [peopleServicesSwitcher, businessServicesSwitcher].find(
    //                 (item) => item !== activeSwitcher,
    //             );
    //             sessionStorage.setItem('current-services-switcher', activeSwitcher.name);

    //             tl.to(nonActiveSwitcher.container, 0.3, {
    //                 y: 30,
    //                 opacity: 0,
    //                 onComplete: () => {
    //                     nonActiveSwitcher.setActiveState(false);
    //                     activeSwitcher.setActiveState(true);
    //                 },
    //             }).fromTo(
    //                 activeSwitcher.container,
    //                 0.3,
    //                 { y: 30, opacity: 0 },
    //                 {
    //                     y: 0,
    //                     opacity: 1,
    //                 },
    //             );
    //         });
    //     }

    //     const activeSwitcher = listSwitch.checked ? businessServicesSwitcher : peopleServicesSwitcher;
    //     const nonActiveSwitcher = [peopleServicesSwitcher, businessServicesSwitcher].find(
    //         (item) => item !== activeSwitcher,
    //     );
    //     nonActiveSwitcher.setActiveState(false);
    // }

    // Sliders

    const sliderElements = Array.from(document.querySelectorAll('.js-about-slider'));

    sliderElements.forEach((el) => {
        if (Array.from(el.querySelectorAll('.glide__slide')).length > 1) {
            createArrows(el, {});
            const slider = new Glide(el, { animationDuration: 1500 });
            slider.mount();
        }
    });

    serviceSlider.init();
    videoSlider.init();

    // const serviceLinks = Array.from(document.querySelectorAll('.js-service-link'));

    // serviceLinks.forEach((link) => {
    //     link.addEventListener('click', (event) => {
    //         if (event.shiftKey || event.metaKey || event.altKey || event.ctrlKey) return;

    //         event.preventDefault();

    //         const currentService = findParent('.js-services-list-item', link).id;
    //         const currentSwitcher = findParent('.js-screen-services-container', link);

    //         sessionStorage.setItem('current-service', currentService);

    //         if (currentSwitcher) {
    //             sessionStorage.setItem(
    //                 'current-services-switcher',
    //                 currentSwitcher.classList.contains('js-services-list--business') ? 'business' : 'people',
    //             );
    //         }
    //     });

    //     link.addEventListener('mousedown', (event) => {
    //         if (event.which !== 1 || event.shiftKey || event.metaKey || event.altKey || event.ctrlKey) return;
    //         const { screenX } = event;
    //         listenOnce(link, 'mouseup', (e) => {
    //             if (Math.abs(e.screenX - screenX) < 10) {
    //                 if (link.getAttribute('data-transition') === 'dark') {
    //                     if (darkPreloader) {
    //                         darkPreloader.classList.add('preloader--next-page');
    //                         darkPreloader.classList.remove('preloader--leave');
    //                         listenOnce(darkPreloader, 'transitionend', () => {
    //                             window.location.href = link.href;
    //                         });
    //                     }
    //                 } else {
    //                     window.location.href = link.href;
    //                 }
    //             }
    //         });
    //     });
    // });

    // Modal

    let prevHash = '';

    const modal = new Modal('modal', {
        beforeOpen() {
            prevHash = window.location.hash;
            window.location.hash = '#zayavka';
        },
        beforeClose() {
            window.location.hash = prevHash || prevHash !== '#zayavka' ? prevHash : '';
        },
    });
    modal.init();

    if (window.location.hash === '#zayavka') {
        modal.open();
    }

    // Forms

    const forms = Array.from(document.querySelectorAll('.js-ajax-form'));

    function sendYaTarget(counterId, strTarget) {
        if (counterId !== undefined && strTarget !== undefined && ym) {
            ym(counterId, 'reachGoal', strTarget);
        }
    }
    function sendGaTarget(strTarget, eventCategory, eventAction) {
        if (eventCategory !== undefined && strTarget !== undefined && eventAction !== undefined && gtag) {
            gtag('event', strTarget, { event_category: eventCategory, event_action: eventAction });
        }
    }

    if (forms.length > 0) {
        Promise.all([loadValidator(), loadAjaxFormSender()])
            .then(([Validator, AjaxFormSenderModule]) => {
                forms.forEach((form) => {
                    const AjaxFormSender = AjaxFormSenderModule.default;
                    const validator = new Validator(form);
                    const { inputs } = validator;

                    form.setAttribute('novalidate', true);
                    form.addEventListener('submit', (event) => {
                        event.preventDefault();
                        const isFormValid = validator.validate();

                        if (isFormValid) {
                            const sender = new AjaxFormSender(form, {
                                onBeforeSend: () => {
                                    const checkInput = form.querySelector('input.js-check-val');
                                    if (checkInput) {
                                        checkInput.value = '';
                                    }
                                },
                                onSuccess: ({ success, message }) => {
                                    const successMessage = form.querySelector('.js-form-message__success');
                                    const failureMessage = form.querySelector('.js-form-message__failure');

                                    if (success) {
                                        const formYaTarget = form.getAttribute('data-ya-target');
                                        const formYaCounterId = form.getAttribute('data-ya-counter-id');
                                        if (formYaTarget && formYaCounterId) {
                                            sendYaTarget(formYaCounterId, formYaTarget);
                                        }
                                        const formGaTarget = form.getAttribute('data-ga-target');
                                        const formGaEventCategory = form.getAttribute('data-ga-event_category');
                                        const formGaEventAction = form.getAttribute('data-ga-event_action');
                                        if (formGaTarget && formGaEventCategory && formGaEventAction) {
                                            sendGaTarget(formGaTarget, formGaEventCategory, formGaEventAction);
                                        }
                                        if (successMessage) {
                                            successMessage.textContent = message;
                                            successMessage.classList.remove('form-message--hidden');
                                        }
                                    } else if (failureMessage) {
                                        failureMessage.textContent = message;
                                        failureMessage.classList.remove('form-message--hidden');
                                    }
                                },
                                onComplete: () => {
                                    const inputElements = Array.from(form.querySelectorAll('.input-group'));
                                    const messagesContainer = form.querySelector('.js-form-messages');
                                    const messages = Array.from(form.querySelectorAll('.js-form-message'));

                                    inputElements.forEach((input) => input.classList.add('input-group--hidden'));

                                    if (messagesContainer) {
                                        messagesContainer.classList.remove('form-messages--hidden');
                                    }

                                    setTimeout(() => {
                                        inputElements.forEach((input) => input.classList.remove('input-group--hidden'));

                                        if (messagesContainer) {
                                            messagesContainer.classList.add('form-messages--hidden');
                                        }

                                        if (form.classList.contains('js-modal__form')) {
                                            modal.close().then(() => {
                                                messages.forEach((el) => el.classList.add('form-message--hidden'));
                                            });
                                        }
                                    }, 3000);
                                },
                            });

                            sender.send().catch((err) => {
                                throw err;
                            });
                        }
                    });

                    inputs.forEach((input) => {
                        input.addEventListener('focus', () => input.classList.remove('is-error'));
                    });
                });
            })
            .catch((err) => {
                throw err;
            });
    }

    Array.from(document.querySelectorAll('[data-modal-open="modal"]')).forEach((btn) => {
        btn.addEventListener('click', () => {
            const modalForm = document.querySelector('.js-modal__form');
            if (btn.dataset.gaTarget) {
                modalForm.dataset.gaTarget = btn.dataset.gaTarget;
            }
            if (btn.dataset.gaEvent_category) {
                modalForm.dataset.gaEvent_category = btn.dataset.gaEvent_category;
            }
            if (btn.dataset.gaEvent_action) {
                modalForm.dataset.gaEvent_action = btn.dataset.gaEvent_action;
            }
            if (btn.dataset.yaTarget) {
                modalForm.dataset.yaTarget = btn.dataset.yaTarget;
            }
        });
    });

    // Map

    let currentCenter = [];

    Array.from(document.querySelectorAll('[data-modal-open="map"]')).forEach((btn) => {
        btn.addEventListener('click', () => {
            currentCenter = [btn.dataset.lat, btn.dataset.lng];
        });
    });

    const YMAPS_API_KEY = '525def55-974d-4597-877d-6630dd5919b6';
    const mapModal = new Modal('map');
    mapModal.init();
    let map;

    mapModal.on('before-open', () => {
        if (map instanceof Map) {
            map.setCenter(currentCenter);
        }
    });

    mapModal.one('before-open', () => {
        loadScript(`https://api-maps.yandex.ru/2.1/?apikey=${YMAPS_API_KEY}&lang=ru_RU`)
            .then(() => {
                const mapContainer = document.querySelector('.js-map');
                if (mapContainer) {
                    map = new Map(mapContainer);
                    map.setCenter(currentCenter);
                }
            })
            .catch((err) => console.error(err));
    });

    // Back link
    const backLinks = Array.from(document.querySelectorAll('.js-back-link'));

    backLinks.forEach((link) => {
        link.addEventListener('click', (event) => {
            event.preventDefault();
            if (!document.referrer) {
                window.location.href = link.href;
            } else {
                window.history.back();
            }
        });
    });

    mapPopup.init();
    mapCity.init();
    mapSearch.init();

    // Initial animation
    async function start() {
        await initPreloader();
        playStartingAnimation();
    }

    if (preview instanceof HTMLVideoElement) {
        if (preview.readyState === 4) {
            start();
        } else if (preview.hasAttribute('src')) {
            listenOnce(preview, 'canplaythrough', start);
            listenOnce(preview, 'error', start);
        } else {
            start();
        }
    } else {
        start();
    }

    const servicesItems = Array.from(document.querySelectorAll('.js-services-item'));
    const servicesBg = document.querySelector('.js-services-bg');
    const servicesList = document.querySelector('.js-services-list');

    if (window.matchMedia('(min-width: 1025px)').matches) {
        if (servicesList && servicesItems) {
            servicesItems.forEach((item) => {
                item.addEventListener('mouseover', function (e) {
                    servicesBg.style.backgroundImage = `url('${this.dataset.bg}')`;
                });
            });

            servicesList.addEventListener('mouseleave', function () {
                servicesBg.style.backgroundImage = '';
            });
        }
    }

    let serviceCurrentCenter;

    const coordsList = Array.from(document.querySelectorAll('.js-map-coords'));

    if (coordsList.length > 0) {
        const activeTab = coordsList.find((el) => el.classList.contains('tab-control--active'));
        const index = coordsList.indexOf(activeTab);
        serviceCurrentCenter = [coordsList[index].dataset.lat, coordsList[index].dataset.lng];
    }

    const mapContainer = document.querySelector('.js-service-map');
    if (mapContainer) {
        loadScript(`https://api-maps.yandex.ru/2.1/?apikey=${YMAPS_API_KEY}&lang=ru_RU`)
            .then(() => {
                map = new Map(mapContainer);
                if (serviceCurrentCenter) {
                    map.setCenter(serviceCurrentCenter);
                }
            })
            .catch((err) => console.error(err));
    }

    document.addEventListener('tab-change', (e) => {
        if (map instanceof Map) {
            map.setCenter([e.detail.lat, e.detail.lng]);
        }
    });

    const containers = Array.from(document.querySelectorAll('.js-drag-swiper-container'));

    headerObserve.init();
    loadMore.init();
    gallery.init();
    gallerySlider.init();
    dragSliders.init(containers);
    initContentList();
    initAboutIndexSlider();

    customElements.define('app-yandex-map', YandexMap);
    // initSelects();
    actionsButtonInit();

    cityPickerSearch.init();

    InitPreviewSwitch();
});
